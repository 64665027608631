import styled from "styled-components/macro";
import { Box, Button } from "~styled";

const Form = styled(Box)`
  .form-title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 28px;
  }
  .form-submission-success {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 28px;
    text-align: center;

    &:before {
      content: "\f058";
      font-family: "Font Awesome 5 Free";
      padding-right: 10px;
      font-weight: 700;
      color: #fe5b32;
    }
  }
  .form-floating {
    label {
      padding-left: 25px;
      font-size: 15px;
      min-height: 45px;
      padding-top: 0;
      padding-bottom: 0;
      color: rgba(38 39 41, 0.5);
    }

    textarea {
      min-height: 150px;
      padding-top: 35px !important;

      & ~ label {
        margin-top: 20px;
      }
    }

    input {
      & ~ label {
        display: flex;
        align-items: center;
      }
    }
  }

  .form-control {
    background: #fff;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    border: 1px solid rgb(38 39 41 / 10%) !important;
  }

  .is-invalid {
    border-color: red !important;

    & ~ label {
      padding-bottom: 22px !important;
    }
  }

  .invalid-feedback {
    padding-left: 15px;
  }

  input.form-control {
    border-radius: 500px;
    background: #fff;
    color: rgb(38 39 41);
    height: 45px !important;

    &::placeholder {
      color: transparent;
    }
  }

  textarea {
    min-height: 175px;
    border-radius: 15px;

    & ~ label {
    }
  }
`;
Form.Button = styled(Button)`
  color: #fff !important;
  border-color: #fe5b32;
  background-color: #fe5b32;
  box-shadow: 0 20px 20px rgb(255 75 96 / 30%);
  border-radius: 500px;

  &:hover {
    box-shadow: 0 20px 20px rgb(254 91 50 / 0%);
    color: #fff !important;
  }
`;
export default Form;
