import React from "react";
import Promo from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import ReactTypingEffect from "react-typing-effect";
import OfferData from "~data/services/Offers";
import { I18nextContext } from "gatsby-plugin-react-i18next";

export default function PromoSection({ ...rest }) {
  const context = React.useContext(I18nextContext);

  const { t } = useTranslation();
  const countryNames = [...new Set(OfferData.map((obj) => obj.country))];

  return (
    <Promo>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-12 text-center">
            <Promo.Subtitle fontColor="#fe5b32">
              <Trans>JobExplore.EU offers you</Trans>
            </Promo.Subtitle>
            <Promo.Title as="h2" className={`promo-title-${context.language}`}>
              <Trans>Jobs in</Trans>
              <br className="d-block" />
              <ReactTypingEffect
                text={[...countryNames.map((name) => t(`in ${name}`) + ".")]}
                className="highlight-text d-inline-block text-primary"
                speed="100"
                eraseSpeed="50"
                typingDelay="500"
                eraseDelay="2500"
                cursorClassName="typed-cursor"
              />
            </Promo.Title>
            <Promo.Text>
              <Trans>For holders of European and biometric passports.</Trans>
            </Promo.Text>
            <Promo.Button
              href="#"
              rounded={true}
              sizeX="180px"
              sizeY="56px"
              mt="20px"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            >
              <Trans>Apply now</Trans>
            </Promo.Button>
          </Col>
        </Row>
      </Container>
    </Promo>
  );
}
