import React from "react";
import { Col, Container, Row, Tab } from "react-bootstrap";
import Transportation from "./style";
import { Trans } from "gatsby-plugin-react-i18next";
import { Link } from "~components";

export default function TransportationSection({ ...rest }) {
  return (
    <Transportation id="transportation" {...rest}>
      <Container>
        <Row className="justify-content-center">
          <Transportation.Box>
            <Transportation.Title as="h6" mb="35px">
              <Trans>
                Transportation to the destination is offered by our partner
              </Trans>
            </Transportation.Title>
            <Transportation.BusLineContainer>
              <a target="_blank" href="https://busline.md/en/">
                <Transportation.BusLineLogo />
              </a>
            </Transportation.BusLineContainer>
          </Transportation.Box>
        </Row>
      </Container>
    </Transportation>
  );
}
