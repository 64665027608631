import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Pricing from "./style";
import PricingCard from "./Components/Card";
import { Trans } from "gatsby-plugin-react-i18next";
import OfferData from "~data/services/Offers";

export default function PricingSection({ ...rest }) {
  return (
    <Pricing id="our-offers" backgroundColor="#f3f4f6" {...rest}>
      <Container>
        <Pricing.Box pb="40px" pbXL="70px">
          <Row className="justify-content-center">
            <Col xs="12" className="col-xl-8 col-lg-7">
              <Pricing.Box className="text-center">
                <Pricing.Title>
                  <Trans>Our job offers</Trans>
                </Pricing.Title>
              </Pricing.Box>
            </Col>
          </Row>
        </Pricing.Box>
        <Row className="justify-content-center">
          {OfferData.map((offer, index) => {
            return (
              <Col
                xs="12"
                className="col-lg-5 col-md-6 col-sm-10 col-xs-10"
                key={index}
              >
                <PricingCard
                  country={offer.country}
                  backgroundImage={offer.backgroundImage}
                  monthlyWage={offer.monthlyWage}
                  hourlyWage={offer.hourlyWage}
                  currency={offer.currency}
                  jobTitle={offer.jobTitle}
                  jobSubtitle={offer.jobSubtitle}
                  requiresEuropeanPassport={offer.requiresEuropeanPassport}
                  isFrom={offer.isFrom}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Pricing>
  );
}
