import styled from "styled-components/macro";
import { Box } from "~styled";

const VideoHero = styled(Box)`
  margin-top: 75px;
  position: relative;
  .bg-vid {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    z-index: 0;
  }

  @media (min-width: 575px) {
    .bg-vid {
      height: 50vh;
    }
  }

  @media (min-width: 768px) {
    .bg-vid {
      height: 60vh;
    }
  }

  @media (min-width: 768px) {
    .bg-vid {
      height: 70vh;
    }
  }
`;

VideoHero.Box = styled(Box)``;

export default VideoHero;
