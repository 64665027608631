import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from "~sections/services/Hero";
import VideoHeroSection from "~sections/services/VideoHero";
import ServicesSection from "~sections/services/Services";
import AboutSection from "~sections/services/About";
import ContentSectionOne from "~sections/services/ContentOne";
import ProcessSection from "~sections/services/Process";
import PromoSection from "~sections/services/Promo";
import PricingSection from "~sections/services/Pricing";
import ContactSection from "~sections/services/Contact";
import HeaderButton from "~sections/services/Header";
import FaqSection from "~sections/services/FaqSection";
import TransportationSection from "~sections/services/TransportationSection";
import FooterTwo from "~sections/services/FooterTwo";
import { graphql } from "gatsby";
import { Layout } from "~components/Core";

export default function Services() {
  const header = {
    headerClasses:
      "site-header site-header--menu-center light-header site-header--with-border site-header--sticky",
    containerFluid: false,
    darkLogo: true,
  };

  return (
    <Layout>
      <PageWrapper headerConfig={header}>
        {/* <HeroSection /> */}
        <VideoHeroSection />
        <PromoSection />
        {/* <ServicesSection /> */}
        <PricingSection />
        {/* <AboutSection /> */}
        {/* <ContentSectionOne /> */}
        <TransportationSection />
        <ProcessSection />
        <FaqSection />
        <ContactSection />
        <FooterTwo />
      </PageWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
