const ProcessData = [
  {
    id: "ps1",
    icon: "1",
    iconBackground: "#fe5b32",
    title: "Step 1",
    text: "Step 1 description",
  },
  {
    id: "ps2",
    icon: "2",
    iconBackground: "#fe5b32",
    title: "Step 2",
    text: "Step 2 description",
  },
  {
    id: "ps3",
    icon: "3",
    iconBackground: "#fe5b32",
    title: "Step 3",
    text: "Step 3 description",
  },
];

export default ProcessData;
