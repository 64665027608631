import React from "react";
import VideoHero from "./style";
import HeroVideo from "~video/hero-lite.mp4";

export default function VideoHeroSection() {
  return (
    <VideoHero>
      <video
        autoPlay="autoplay"
        loop="loop"
        muted="muted"
        playsInline
        className="bg-vid"
      >
        <source src={HeroVideo} type="video/mp4" />
      </video>
    </VideoHero>
  );
}
