import React from "react";
import { Accordion, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { AccordionItemFull } from "~components/Accordion";
import Faq from "./style";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

export default function FaqSection({ ...rest }) {
  const { t } = useTranslation();

  return (
    <Faq id="faq" {...rest}>
      <Container>
        <Row className="justify-content-center">
          <Faq.Box>
            <Faq.Title as="h3" mb="61px">
              <Trans>Frequently Asked Questions</Trans>
            </Faq.Title>
          </Faq.Box>
        </Row>
        <Faq.Body>
          <Faq.TabWrapper
            as={Tab.Container}
            id="left-tabs-example"
            defaultActiveKey="first"
          >
            <Row className="justify-content-center">
              <Col className="col-xl-10">
                <Row className="justify-content-center justify-md-content-start">
                  <Col className="col-xl-9 col-lg-8 col-md-11">
                    <Tab.Content className="tab-content tab-content--feature">
                      <Tab.Pane eventKey="first">
                        <Faq.Accordion>
                          <Accordion defaultActiveKey="1a">
                            <AccordionItemFull
                              question={t(`FaqQuestion1`)}
                              answer={t(`FaqAnswer1`)}
                              accKey={`1a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion2`)}
                              answer={t(`FaqAnswer2`)}
                              accKey={`2a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion3`)}
                              answer={t(`FaqAnswer3`)}
                              accKey={`3a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion4`)}
                              answer={t(`FaqAnswer4`)}
                              accKey={`4a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion5`)}
                              answer={t(`FaqAnswer5`)}
                              accKey={`5a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion6`)}
                              answer={t(`FaqAnswer6`)}
                              accKey={`6a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion7`)}
                              answer={t(`FaqAnswer7`)}
                              accKey={`7a`}
                            />
                            <AccordionItemFull
                              question={t(`FaqQuestion8`)}
                              answer={t(`FaqAnswer8`)}
                              accKey={`8a`}
                            />
                          </Accordion>
                        </Faq.Accordion>
                      </Tab.Pane>
                    </Tab.Content>
                    {/* <Faq.ButtonGroup mt="50px">
                      <p>
                        Haven’t got your answer?
                        <a className="btn-link text-readical-red ms-2" href="#">
                          Contact our support now
                        </a>
                      </p>
                    </Faq.ButtonGroup> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Faq.TabWrapper>
        </Faq.Body>
      </Container>
    </Faq>
  );
}
