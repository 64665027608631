import React from "react";
import Card from "./style";
import {
  Trans,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next";

const languageToLocaleMap = {
  de: "de-DE",
  ro: "ro-MD",
  ru: "ru-RU",
};

const formatAmount = (amount, language, currency = "EUR") => {
  const locale = languageToLocaleMap[language] || "en-UK";

  return amount
    ? new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: amount % 1 === 0 ? 0 : 2,
      }).format(amount)
    : "";
};

const getCountryIcon = (country) => {
  switch (country) {
    case "Germany":
      return (
        <Card.CountryIcon
          height="18px"
          iconSvg="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='600' viewBox='0 0 5 3'%3E%3Cdesc%3EFlag of Germany%3C/desc%3E%3Crect id='black_stripe' width='5' height='3' y='0' x='0' fill='%23000'/%3E%3Crect id='red_stripe' width='5' height='2' y='1' x='0' fill='%23D00'/%3E%3Crect id='gold_stripe' width='5' height='1' y='2' x='0' fill='%23FFCE00'/%3E%3C/svg%3E"
        />
      );
    case "Czech Republic":
      return (
        <Card.CountryIcon
          height="20px"
          iconSvg="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='900' height='600'%3E%3Crect width='900' height='600' fill='%23d7141a'/%3E%3Crect width='900' height='300' fill='%23fff'/%3E%3Cpath d='M 450,300 0,0 V 600 z' fill='%2311457e'/%3E%3C/svg%3E"
        />
      );
    case "Slovakia":
      return (
        <Card.CountryIcon
          height="20px"
          iconSvg="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='900' height='600' viewBox='0 0 10800 7200' fill='%23ee1c25'%3E%3Ctitle%3EFlag of Slovakia%3C/title%3E%3Cdefs%3E%3CclipPath id='c'%3E%3Cpath id='s' d='m4678 1746c0 0 56 561 56 1683-8 1146-865 1726-1494 2031-629-305-1486-885-1494-2031 0-1122 56-1683 56-1683z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='scale(1200)'%3E%3Cpath fill='%23fff' d='m0 0h9v6H0z'/%3E%3Cpath fill='%230b4ea2' d='m0 2h9v4H0z'/%3E%3Cpath d='m0 4h9v2H0z'/%3E%3C/g%3E%3Cuse xlink:href='%23s'/%3E%3Cpath fill='%23fff' d='m3113 4800V3397c-475 0-610 42-752 85a2323 2323 0 0 0 0-431c142 43 277 85 752 85v-390c-150 0-357 6-595 87a2323 2323 0 0 0 0-432c238 80 445 86 595 86 0-113-35-356-79-557a2323 2323 0 0 0 412 0c-44 201-79 444-79 557 150 0 357-6 595-86a2323 2323 0 0 0 0 432c-238-81-445-87-595-87v390c475 0 610-42 752-85a2323 2323 0 0 0 0 431c-142-43-277-85-752-85v1403z'/%3E%3Cpath clip-path='url(%23c)' fill='%230b4ea2' d='m2463 5903a507 867 0 1 1 319-1541 584 1084 0 0 1 916 0 507 867 0 1 1 319 1541z'/%3E%3Cuse xlink:href='%23s' fill='none' stroke='%23fff' stroke-width='108'/%3E%3C/svg%3E"
        />
      );
    case "Poland":
      return (
        <Card.CountryIcon
          height="19px"
          iconSvg="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='640' height='400' viewBox='0 0 8 5'%3E%3Crect width='8' height='5' fill='%23dc143c'/%3E%3Crect width='8' height='2.5' fill='%23fff'/%3E%3C/svg%3E"
        />
      );
    default:
      return null;
  }
};

export default function PricingCard({
  country,
  backgroundImage,
  monthlyWage,
  hourlyWage,
  currency,
  jobTitle,
  jobSubtitle,
  requiresEuropeanPassport,
  isFrom,
  ...rest
}) {
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);

  return (
    <Card backgroundColor="#fff" {...rest}>
      <Card.Top backgroundImage={backgroundImage}></Card.Top>
      <Card.List pt="40px">
        <Card.ListItemCountryContainer>
          {getCountryIcon(country)}
          <Card.ListItemCountry>
            <Trans>{country}</Trans>
          </Card.ListItemCountry>
        </Card.ListItemCountryContainer>
        <Card.ListItemWage>
          <Trans>Wage</Trans>
          <br className="d-xs-block" />
          {!!isFrom ? t("from") + " " : ""}
          {monthlyWage
            ? t("salaryPerMonth", {
                amount: formatAmount(monthlyWage, context.language, currency),
              })
            : t("salaryPerHour", {
                amount: formatAmount(hourlyWage, context.language, currency),
              })}
        </Card.ListItemWage>
        <Card.ListItemTitle>
          <Trans>{jobTitle}</Trans>
        </Card.ListItemTitle>
        <Card.ListItemSubtitle>
          <Trans>{jobSubtitle}</Trans>
        </Card.ListItemSubtitle>
      </Card.List>
      <Card.Box>
        <a href="tel:+37362000050">
          <Card.Button
            textColor="#fff"
            hoverTextColor="#fff"
            backgroundColor="#fe5b32"
            hoverBackgroundColor="#fe5b32"
            rounded={true}
            sizeX="180px"
            sizeY="56px"
          >
            <Trans>Call us</Trans>
          </Card.Button>
        </a>
        {!!requiresEuropeanPassport ? (
          <Card.ListItemPassportContainer>
            <Card.EuIcon />
            <Card.ListItemPassport>
              {t("For EU passport holders")}
            </Card.ListItemPassport>
          </Card.ListItemPassportContainer>
        ) : null}
      </Card.Box>
    </Card>
  );
}
