import React, { useState } from "react";
import Form from "./style";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

export default function ContactForm({ ...rest }) {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = t("Name is required");
    }
    if (!phone.trim()) {
      errors.phone = t("Phone number is required");
    } else if (!/^\+?[0-9()\s-]{8,20}$/i.test(phone.trim())) {
      errors.phone = t("Please enter a valid phone number");
    }
    if (comments.trim().length > 5000) {
      errors.comments = t("Your comment is too long");
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setSubmitted(true);

      const myForm = event.target;
      const formData = new FormData(myForm);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => console.log("Form successfully submitted"))
        .catch((error) => alert(error));
    } else {
      setErrors(errors);
    }
  };

  const { originalPath } = useI18next();

  return (
    <Form {...rest}>
      {submitted ? (
        <h3 className="form-submission-success">
          <Trans>
            Thank you for your message, we will contact you shortly.
          </Trans>
        </h3>
      ) : (
        <>
          <h3 className="form-title">
            <Trans>
              Enter your details below, and we will contact you shortly.
            </Trans>
          </h3>
          <div className="contact-form">
            <form
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              method="post"
              action={originalPath}
            >
              <input className="d-none" type="hidden" name="bot-field" />
              <input
                className="d-none"
                type="hidden"
                name="form-name"
                value="contact"
              />
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div className="form-floating">
                    <input
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Your name"
                      id="floatinginput"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="floatinginput">
                      <Trans>Your name</Trans>
                    </label>
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="form-floating">
                    <input
                      className={`form-control ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      placeholder="Phone number"
                      id="floatinginput2"
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label htmlFor="floatinginput2">
                      <Trans>Phone number</Trans>
                    </label>
                    {errors.name && (
                      <div className="invalid-feedback">{errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-floating">
                    <textarea
                      className={`form-control ${
                        errors.comments ? "is-invalid" : ""
                      }`}
                      placeholder="Comments"
                      id="floatingTextarea"
                      style={{ height: 200 }}
                      defaultValue={""}
                      name="comments"
                      onChange={(e) => setComments(e.target.value)}
                    />
                    <label htmlFor="floatingTextarea">
                      <Trans>The job offer that interests you</Trans>
                    </label>
                    {errors.name && (
                      <div className="invalid-feedback">{errors.comments}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row align-items-center ">
                    <div className=" text-center pt-3">
                      <Form.Button
                        href="#"
                        rounded={true}
                        sizeX="180px"
                        sizeY="56px"
                        mt="20px"
                      >
                        <Trans>Submit</Trans>
                      </Form.Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </Form>
  );
}
