import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "./Components/ContactForm";
import Contact from "./style";
import { Trans } from "gatsby-plugin-react-i18next";

export default function ContactSection({ ...rest }) {
  return (
    <Contact {...rest} backgroundColor="#f3f4f6" id="contact">
      <Container>
        <Row className="justify-content-center text-center">
          <Col xs="12" className="col-xl-10">
            <Contact.Box>
              <Contact.Title as="h2" pb="20px">
                <Trans>Application form</Trans>
              </Contact.Title>
            </Contact.Box>
          </Col>
          <Col xs="12" className="col-lg-10 mb-7 mb-lg-0">
            <ContactForm className="text-start" mt="50px" />
          </Col>
        </Row>
      </Container>
    </Contact>
  );
}
