import sortingChickenMeat from "~image/home-services/job-offers/sorting-chicken-meat.png";
import processingChickenMeat from "~image/home-services/job-offers/processing-chicken-meat.jpeg";
import processingFish from "~image/home-services/job-offers/processing-fish.jpeg";
import packingCosmetics from "~image/home-services/job-offers/packing-cosmetics.jpg";
import warehouseWorker from "~image/home-services/job-offers/warehouse-worker.jpg";
import cuttingApples from "~image/home-services/job-offers/cutting-apples.jpg";
import technologistCheeseShop from "~image/home-services/job-offers/technologist-cheese-shop.jpg";
import construction from "~image/home-services/job-offers/construction.jpg";
import forkliftOperator from "~image/home-services/job-offers/forklift-operator.png";
import carParts from "~image/home-services/job-offers/car-parts.png";
import volkswagenFactory from "~image/home-services/job-offers/volkswagen-factory.png";
// paid
import nutProcessing from "~image/home-services/job-offers/nut-processing.jpg";
import bakery from "~image/home-services/job-offers/bakery.jpg";
import cosmetics from "~image/home-services/job-offers/cosmetics.jpg";
import milkPacking from "~image/home-services/job-offers/milk-packing.jpg";
import cleaningLady from "~image/home-services/job-offers/cleaning-lady.jpg";
import poultryFarm from "~image/home-services/job-offers/poultry-farm.jpg";
import amazonWarehouse from "~image/home-services/job-offers/amazon-warehouse.jpg";
import foodFactory from "~image/home-services/job-offers/food-factory.jpg";
import foodFactoryAssistant from "~image/home-services/job-offers/food-factory-assistant.jpg";

const OfferData = [
  {
    country: "Germany",
    backgroundImage: processingFish,
    monthlyWage: "2000",
    jobTitle: "Worker in a fish factory",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: packingCosmetics,
    monthlyWage: "2000",
    jobTitle: "Worker in the cosmetics warehouse",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: amazonWarehouse,
    monthlyWage: "2200",
    jobTitle: "Amazon warehouse",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: foodFactory,
    monthlyWage: "2200",
    jobTitle: "Worker in a food factory",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: foodFactoryAssistant,
    monthlyWage: "2200",
    jobTitle: "Assistant in the production of food products",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: construction,
    hourlyWage: "18",
    jobTitle: "Construction",
    jobSubtitle: "Men",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Germany",
    backgroundImage: forkliftOperator,
    hourlyWage: "13.5",
    jobTitle: "Forklift Operator",
    jobSubtitle: "Men",
    isFrom: true,
    requiresEuropeanPassport: true,
  },
  {
    country: "Slovakia",
    backgroundImage: carParts,
    monthlyWage: "1200",
    jobTitle: "Unskilled workers at the auto parts factory",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Slovakia",
    backgroundImage: volkswagenFactory,
    monthlyWage: "1200",
    jobTitle: "Volkswagen automobile manufacturing plant",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Czech Republic",
    backgroundImage: sortingChickenMeat,
    monthlyWage: "1200",
    jobTitle: "Weighing and packaging of chicken meat",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Czech Republic",
    backgroundImage: processingChickenMeat,
    monthlyWage: "1200",
    jobTitle: "Processing of chicken meat",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: nutProcessing,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Nut processing",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: bakery,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Production of bakery products",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: cosmetics,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Cosmetics production worker",
    jobSubtitle: "Men",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: milkPacking,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Packer at a dairy plant",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: cleaningLady,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Cleaner at a cosmetics production facility",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
  {
    country: "Poland",
    backgroundImage: poultryFarm,
    hourlyWage: "22",
    currency: "PLN",
    jobTitle: "Poultry farm",
    jobSubtitle: "Married couples, men and women.",
    isFrom: true,
  },
];

export default OfferData;
